import React from 'react';
import {
	Content,
  Title,
  ContentRow,
	ContentColumn,
  Button
} from './PrivacyStyles';

const Privacy = ({ contactPrivacyParent, privacyPolicyCallback }) => {

  const handlePrivacyPolicy = (value) => {
    privacyPolicyCallback(value);
  };

	return (
    <Content>
      <ContentRow>
        <ContentColumn>
          <Title>{contactPrivacyParent.title}</Title>
          <h3>{contactPrivacyParent.parag1}</h3>
          <p>{contactPrivacyParent.parag1Text}</p>
          <h3>{contactPrivacyParent.parag2}</h3>
          <p>{contactPrivacyParent.parag2Text}</p>
          <h3>{contactPrivacyParent.parag3}</h3>
          <p>{contactPrivacyParent.parag3Text}</p>
          <h3>{contactPrivacyParent.parag4}</h3>
          <p>{contactPrivacyParent.parag4Text}</p>
          <h3>{contactPrivacyParent.parag5}</h3>
          <p>{contactPrivacyParent.parag5Text}</p>
        </ContentColumn>
      </ContentRow>
      <ContentRow>
        <ContentColumn className="button">
          {contactPrivacyParent.buttons?.map((element, index) => (
            <Button onClick={() => { handlePrivacyPolicy(false); }}>
              {element.name}
            </Button>
          ))}
        </ContentColumn>
      </ContentRow>
    </Content>
	);
};

export default Privacy;
