import React, { useState, useEffect } from 'react';
import {
	CertificationsSection,
	ContentRow,
	ContentColumn,
	LeftContent,
	ImageCharacter,
	Title,
	CertificationsText,
	CertificationsContent,
  Bold,
  DetailList,
  CompanyUrl,
  CertificationUrl,
  ValidationURL
} from './CertificationsStyles';

const Certifications = ({ certificationsParentByLanguage }) => {

  const [certifications, setCertifications] = useState({});

  // [certificationsParentByLanguage] ==> Whenever the "certificationsParentByLanguage" state variable change...
  useEffect(() => {
    // ... after it has been populated...
    if (certificationsParentByLanguage.body != null)
      setCertifications(certificationsParentByLanguage.body.certifications);
  }, [certificationsParentByLanguage]);

  return (
    <CertificationsSection>
      <CertificationsContent>
        <Title>
          {certifications.title}
        </Title>
        
        {certifications.items?.map((element, index) => (
          <ContentRow  key={index}>
            <ContentColumn className="image">
              <ImageCharacter src={element.imageUrl} />
            </ContentColumn>
            <ContentColumn className="description">
              <LeftContent>
                <CertificationsText><Bold>{element.period}:</Bold> {element.startDate} - {element.endDate}</CertificationsText>
                <CertificationsText><Bold>{element.nameTitle}:</Bold> {element.name}</CertificationsText>
                <CertificationsText><Bold>{element.descriptionTitle}:</Bold> {element.description}</CertificationsText>
                {element.descriptionDetails !== null ?
                    <DetailList>
                      {element.descriptionDetails.map((elementDetail, index) => (
                        elementDetail !== "*" ? <li key={index}>{elementDetail}</li> : "\n"
                      ))}
                    </DetailList>
                  :
                    ""
                }
                <CertificationsText><Bold>{element.timeTitle}:</Bold> {element.time}</CertificationsText>
                <CertificationsText><Bold>{element.sectorTitle}:</Bold> {element.sector}</CertificationsText>
                {element.companyTitle !== null ?
                    <CertificationsText><Bold>{element.companyTitle}:</Bold>
                      <span>{" "}</span>
                      
                      <CompanyUrl href={element.companyUrl} target="_blank">
                        {element.company}
                      </CompanyUrl>

                      {element.companyStreet !== null ?
                        " - " + element.companyStreet
                      :
                        ""
                      }
                    </CertificationsText>
                  :
                    ""
                }
                <CertificationsText><Bold>{element.certificationImageUrlTitle}:</Bold>
                  <span>{" "}</span>

                  <CertificationUrl href={element.certificationImageUrl} target="_blank">
                    {element.certificationImageUrlName}
                  </CertificationUrl>
                </CertificationsText>
                <CertificationsText><Bold>{element.validationLinkTitle}:</Bold>
                  <span>{" "}</span>

                  <ValidationURL href={element.validationLink} target="_blank">
                    {element.validationLinkName}
                  </ValidationURL>
                </CertificationsText>
              </LeftContent>
            </ContentColumn>
          </ContentRow>
        ))}
      </CertificationsContent>
    </CertificationsSection>
  )
}

export default Certifications;