import styled from 'styled-components';
import { Section } from '../../../globalStyles';

export const Content = styled(Section)`
  padding: 3rem 0 0;
	width: 100%;
	z-index: 10;
	position: relative;
`;

export const Title = styled.h2`
	font-size: clamp(1.75rem, 2.7vw, 2.25rem);
	line-height: 1;
	font-weight: 500;
	text-align: center;
`;

export const ContentRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 1rem;
`;

export const ContentColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
  white-space: pre-line;
  width: 70vw;

	&.button {
		align-items: end;
		padding: 0rem 2rem;
	}

  > h3 {
    padding: 2rem 0 1rem;
  }

  > p {
    text-align: justify;
  }

  @media screen and (max-width: 720px) {
		&.button {
			align-items: center;
			padding: 0rem;
		}
  
    > h3 {
      font-size: 1rem;
    }
  
    > p {
      font-size: 0.85rem;
    }
  }
`;

export const Button = styled.button`
  height: 3rem;
  padding: 0.8rem 2rem;
  margin: 1rem 0;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 1.5rem;
  border: none;
  background: linear-gradient(150deg, #ccc -10%, #636363);
  cursor: pointer;
  color: white;

  &:hover {
		box-shadow: 0 0 2rem 0.75rem #666;
		transition: box-shadow 0.4s ease-in;
  }
`;
